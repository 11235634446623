export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lighttextDesc: true,
  topLine: 'Over All',
  headline: 'WHAT WE DO',
  description: 'Our coaching options have been designed from the ground up to put you and your goals first. From one-on-one training in your private studio, to our renowned small group personal training and bespoke nutrition packages- everything we offer has been battle-tested over a decade in the field, to ensure the results you achieve are not only impressive in the short term- but also sustainable for life',
  buttonLabel: 'Get started',
  imgStart: false,
  img: '/images/fit33.svg',
  alt: 'car',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'The Journey',
  headline: 'OUR MISSION',
  description: 'Unlike traditional gyms and too-good-to-be-true weight loss plans, we don’t believe in short-term fixes. Instead, we’ll help you to set realistic goals - and achieve them',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: '/images/compass.svg',
  alt: 'Piggybanck',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lighttextDesc: false,
  topLine: 'Client Success',
  headline: 'GETTING RESULTS',
  description: "Whether that means gaining the confidence to wear a two-piece at the beach after having their first child, fitting into the suit they always imagined they'd wear for their daughters wedding, or the everyday confidence that permeates everything they do, installed through the effort they've put in at the studio. ",
  buttonLabel: 'Start Now',
  imgStart: false,
  img: '/images/statistics.svg',
  alt: 'car',
  primary: false,
  darkText: true
};
